import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface SidePanelProps {
  open: boolean;
  toggleDrawer: () => void;
}

const SidePanel: React.FC<SidePanelProps> = ({ open, toggleDrawer }) => {
  const { t } = useTranslation();

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Typography variant="h6" sx={{ m: 2 }}>
        {t("dashboard.title")}
      </Typography>
      <Divider />
      <List>
        {/* <ListItem component={RouterLink} to="/clients">
          <ListItemText primary={t("clients.title")} />
        </ListItem> */}
      </List>
    </Box>
  );

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer}>
      {list()}
    </Drawer>
  );
};

export default SidePanel;
