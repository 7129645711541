import * as yup from "yup";

export const addEditValidationSchema = (t: any) =>
  yup.object().shape({
    isCompany: yup.boolean(),
    foreign: yup.boolean(),
    hasAuthorization: yup.boolean(),
    companyName: yup
      .string()
      .when("isCompany", (isCompany: boolean[], schema: yup.StringSchema) => {
        return isCompany[0] === true
          ? schema.required(t("validation.required"))
          : schema.notRequired();
      }),
    tin: yup
      .string()
      .when("isCompany", (isCompany: boolean[], schema: yup.StringSchema) => {
        return isCompany[0] === true
          ? schema.required(t("validation.required"))
          : schema.notRequired();
      }),
    firstName: yup
      .string()
      .when("isCompany", (isCompany: boolean[], schema: yup.StringSchema) => {
        return isCompany[0] === false
          ? schema.required(t("validation.required"))
          : schema.notRequired();
      }),
    lastName: yup
      .string()
      .when("isCompany", (isCompany: boolean[], schema: yup.StringSchema) => {
        return isCompany[0] === false
          ? schema.required(t("validation.required"))
          : schema.notRequired();
      }),
    identificationNumber: yup.string().required(t("validation.required")),
    address: yup.string().required(t("validation.required")),
    email: yup
      .string()
      .required(t("validation.required"))
      .email(t("validation.invalidEmail")),
    phone: yup.string().required(t("validation.required")),
  });
