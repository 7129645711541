import axios from "axios";

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/"
      : "https://api.premium-polisa.com/", // Replace with your backend API URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor if needed (e.g., to add auth tokens)
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Example token retrieval
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle responses globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.status === 403 &&
      error.response?.data?.message === "Failed to authenticate token"
    ) {
      localStorage.removeItem("token");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
