import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Input,
  CloseReason,
} from "@mui/material";
import { ErrorOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface FileUploadDialogProps {
  open: boolean;
  onClose: (uploadedFile?: File) => void;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string>("");

  const closeDialog = (uploadedFile?: File) => {
    setUploadError("");
    onClose(uploadedFile);
  };

  const handleDialogClose = (_event: CloseEvent, reason: any) => {
    if (reason === ("backdropClick" as CloseReason)) {
      return;
    }
    closeDialog();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    closeDialog(selectedFile!);
  };

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={handleDialogClose}>
      <DialogTitle>Select a file</DialogTitle>
      <DialogContent>
        <Input type="file" onChange={handleFileChange} />
      </DialogContent>
      {uploadError && (
        <DialogContent>
          <div style={{ color: "red", display: "flex", alignItems: "center" }}>
            <ErrorOutlined style={{ marginRight: 8 }} />
            {uploadError}
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={closeDialog as any}>{t("common.cancel")}</Button>
        <Button onClick={handleFileUpload} color="primary" variant="contained">
          {t("common.upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
