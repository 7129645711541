import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ErrorOutlined } from "@mui/icons-material";

interface RemoveAuthorizationDialogProps {
  open: boolean;
  onClose: (removed?: boolean) => void;
}

const RemoveAuthorizationDialog: React.FC<RemoveAuthorizationDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [removeAuthorizationDialogError, setRemoveAuthorizationDialogError] =
    useState("");

  const closeDialog = () => {
    setRemoveAuthorizationDialogError("");
    onClose();
  };

  const removeAuthorization = async () => {
    try {
      onClose(true);
    } catch (error: any) {
      setRemoveAuthorizationDialogError(error.message);
      console.error("Error removing authorization:", error);
      onClose(true);
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t("clients.confirmRemoveAuthorization")}</DialogTitle>
      <DialogContent>
        {t("clients.areYouSureRemoveAuthorization")}
      </DialogContent>
      {removeAuthorizationDialogError && (
        <DialogContent>
          <div style={{ color: "red", display: "flex", alignItems: "center" }}>
            <ErrorOutlined style={{ marginRight: 8 }} />
            {removeAuthorizationDialogError}
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={closeDialog}>{t("common.cancel")}</Button>
        <Button onClick={removeAuthorization} color="error" variant="contained">
          {t("common.remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveAuthorizationDialog;
