import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    color: "white",
    "& .MuiOutlinedInput-root": {
      color: "white",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", // Border color when focused
      },
      "&.Mui-focused": {
        color: "white", // Text color when focused
      },
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    "& .MuiInputLabel-root": {
      color: "white",
      "&.Mui-focused": {
        color: "white", // Label color when focused
      },
    },
    "& .MuiSelect-iconOpen": {
      color: "white", // Dropdown icon color when open
    },
  },
});

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();

  const handleChange = (event: any) => {
    i18n.changeLanguage(event.target.value as string);
  };

  return (
    <FormControl variant="outlined" size="small" className={classes.root}>
      <InputLabel id="language-select-label">
        {t("language.selectLanguage")}
      </InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={i18n.language}
        onChange={handleChange}
        label={t("language.selectLanguage")}
      >
        <MenuItem value="en">{t("language.english")}</MenuItem>
        <MenuItem value="sr">{t("language.serbian")}</MenuItem>
        {/* Add additional languages here */}
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
