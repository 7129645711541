import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PolicyHolder } from "../../api/policyHolderApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { addEditValidationSchema } from "../../forms/schemas/add-edit.client.schema";
import FileUploadDialog from "../Authorization/FileUploadDialog";
import RemoveAuthorizationDialog from "../Authorization/RemoveAuthorization";

interface EditClientDialogProps {
  open: boolean;
  onClose: () => void;
  onEditClient: (
    clientData: Partial<PolicyHolder>,
    authorizationFile: File | null
  ) => void;
  clientData: PolicyHolder | null;
}

const EditClientDialog: React.FC<EditClientDialogProps> = ({
  open,
  onClose,
  onEditClient,
  clientData,
}) => {
  const { t } = useTranslation();
  const [isFileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [authorizationFile, setAuthorizationFile] = useState<File | null>(null);
  const [removeAuthorizationDialogOpen, setRemoveAuthorizationDialogOpen] =
    useState(false);

  const handleSaveClick = (data: any) => {
    let editedClient: Partial<PolicyHolder>;
    if (data.isCompany) {
      const { firstName, lastName, ...editedCompanyData } = data;
      editedClient = editedCompanyData;
    } else {
      const { companyName, tin, ...editedPersonalData } = data;
      editedClient = editedPersonalData;
    }
    onEditClient(editedClient, authorizationFile);
  };

  const handleHasAuthorizationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setFileUploadDialogOpen(true);
    } else {
      setRemoveAuthorizationDialogOpen(true);
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<PolicyHolder>({
    resolver: yupResolver(addEditValidationSchema(t) as any),
    mode: "all", // Validate on blur
    defaultValues: clientData || {
      isCompany: false,
      foreign: false,
      authorization: "",
      companyName: "",
      tin: "",
      firstName: "",
      lastName: "",
      identificationNumber: "",
      address: "",
      email: "",
      phone: "",
    },
  });

  useEffect(() => {
    if (clientData) {
      reset(clientData);
    } else {
      reset({
        isCompany: false,
        foreign: false,
        authorization: "",
        companyName: "",
        tin: "",
        firstName: "",
        lastName: "",
        identificationNumber: "",
        address: "",
        email: "",
        phone: "",
      });
    }
  }, [clientData, open, reset]);

  const isCompany = watch("isCompany");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("clients.editClient")}</DialogTitle>
      <DialogContent>
        <Controller
          name="isCompany"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={t("clients.isCompany")}
            />
          )}
        />
        <Controller
          name="foreign"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={t("clients.foreign")}
            />
          )}
        />
        <Controller
          name="authorization"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value !== "" && field.value !== null}
                  onChange={handleHasAuthorizationChange}
                />
              }
              label={t("clients.hasAuthorization")}
            />
          )}
        />

        {isCompany ? (
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("clients.companyName")}
                required
                fullWidth
                margin="normal"
                error={Boolean(errors.companyName)}
                helperText={
                  errors.companyName?.message && t(errors.companyName.message)
                }
              />
            )}
          />
        ) : (
          <>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("clients.firstName")}
                  required
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.firstName)}
                  helperText={
                    errors.firstName?.message && t(errors.firstName.message)
                  }
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("clients.lastName")}
                  required
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.lastName)}
                  helperText={
                    errors.lastName?.message && t(errors.lastName.message)
                  }
                />
              )}
            />
          </>
        )}

        <Controller
          name="identificationNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("clients.identificationNumber")}
              required
              fullWidth
              margin="normal"
              error={Boolean(errors.identificationNumber)}
              helperText={
                errors.identificationNumber?.message &&
                t(errors.identificationNumber.message)
              }
            />
          )}
        />

        {isCompany && (
          <Controller
            name="tin"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("clients.tin")}
                required
                fullWidth
                margin="normal"
                error={Boolean(errors.tin)}
                helperText={errors.tin?.message && t(errors.tin.message)}
              />
            )}
          />
        )}

        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("clients.address")}
              required
              fullWidth
              margin="normal"
              error={Boolean(errors.address)}
              helperText={errors.address?.message && t(errors.address.message)}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("clients.email")}
              type="email"
              required
              fullWidth
              margin="normal"
              error={Boolean(errors.email)}
              helperText={errors.email?.message && t(errors.email.message)}
            />
          )}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("clients.phone")}
              type="tel"
              required
              fullWidth
              margin="normal"
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message && t(errors.phone.message)}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.cancel")}</Button>
        <Button
          onClick={handleSubmit(handleSaveClick)}
          variant="contained"
          color="primary"
          disabled={!isDirty || !isValid}
        >
          {t("common.save")}
        </Button>
      </DialogActions>
      <FileUploadDialog
        open={isFileUploadDialogOpen}
        onClose={(uploadedFile?: File) => {
          if (uploadedFile) {
            setValue("authorization", uploadedFile.name, { shouldDirty: true });
            setAuthorizationFile(uploadedFile);
          }
          setFileUploadDialogOpen(false);
        }}
      />
      <RemoveAuthorizationDialog
        open={removeAuthorizationDialogOpen}
        onClose={(removed?: boolean) => {
          if (removed) {
            setValue("authorization", "", { shouldDirty: true });
            setAuthorizationFile(null);
          }
          setRemoveAuthorizationDialogOpen(false);
        }}
      />
    </Dialog>
  );
};

export default EditClientDialog;
