import { useState, useEffect, useCallback } from "react";
import { getPolicyHolders } from "../services/policyHolderService";
import {
  PolicyHolder,
  FetchPolicyHoldersParams,
  GetPolicyHoldersResponse,
} from "../api/policyHolderApi";
import { replaceNullsWithEmptyStrings } from "../utils/transform.helper";

interface UseInfiniteScrollProps {
  initialParams: FetchPolicyHoldersParams;
}

const useInfiniteScroll = ({ initialParams }: UseInfiniteScrollProps) => {
  const [clients, setClients] = useState<PolicyHolder[]>([]);
  const [cursor, setCursor] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [params, setParams] = useState<FetchPolicyHoldersParams>(initialParams);

  const fetchClients = useCallback(
    async (passedCursor?: number) => {
      if (loading) return;

      setLoading(true);
      setError(null);

      try {
        const response: GetPolicyHoldersResponse = await getPolicyHolders({
          ...params,
          cursor: passedCursor || passedCursor === 0 ? passedCursor : cursor,
        });

        setClients((prevClients) => {
          const updatedData = response.data.map((client) => ({
            ...replaceNullsWithEmptyStrings(client),
            isCompany: !!client.companyName,
          }));
          const combinedClients = [...prevClients, ...updatedData];
          const uniqueClients = Array.from(
            new Map(
              combinedClients.map((client) => [client.id, client])
            ).values()
          );
          return uniqueClients;
        });

        setCursor(response.nextCursor);
        setHasMore(response.nextCursor !== null);
      } catch (err: any) {
        setError(err.response?.data?.message || "Failed to fetch clients.");
      } finally {
        setLoading(false);
      }
    },
    [params, cursor, loading]
  );

  useEffect(() => {
    if (hasMore) {
      fetchClients();
    }
  }, [params, hasMore, loading]);

  const updateParams = (newParams: Partial<FetchPolicyHoldersParams>) => {
    setParams((prev) => ({
      ...prev,
      ...newParams,
    }));
    setClients([]);
    setCursor(0);
    setHasMore(true);
  };

  return { clients, hasMore, fetchClients, loading, error, updateParams };
};

export default useInfiniteScroll;
