import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  sortIcon: {
    display: "inline-block",
    top: "4px",
    position: "relative",
  },
  searchContainer: {
    display: "flex",
    width: "100%",
    gap: "10px",
  },
});
