import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Box } from "@mui/material";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import SidePanel from "../SidePanel/SidePanel";
import logo from "../../images/logo.png";
import { useStyles } from "./Header.styles";
import useAuth from "../../hooks/useAuth";

const Header: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Box
            component={RouterLink}
            to="/dashboard"
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: "40px", marginRight: "10px" }}
            />
          </Box>

          <Box className={classes.navButton}>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontWeight: "bold",
              }}
              component={RouterLink}
              to="/clients"
            >
              {t("clients.title")}
            </Button>
          </Box>

          <LanguageSwitcher />

          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogout}
            >
              {t("dashboard.logout")}
            </Button>
          </Box>

          {/* Side Panel Toggle Button */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ ml: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Side Panel (Drawer) */}
      <SidePanel open={drawerOpen} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Header;
