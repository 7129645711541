import React, { useCallback, useState, useRef } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Box,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import {
  addPolicyHolderApi,
  deletePolicyHolderApi,
  PolicyHolder,
  updatePolicyHolderApi,
} from "../../api/policyHolderApi";
import {
  Delete,
  Edit,
  ArrowUpward,
  ArrowDownward,
  Clear,
} from "@mui/icons-material"; // Import sort icons
import EditClientDialog from "../../dialogs/Client/EditClient";
import DeleteClientDialog from "../../dialogs/Client/DeleteClient";
import useInfiniteScroll from "../../hooks/infiniteScroll";
import { useStyles } from "./Clients.styles";
import axiosInstance from "../../utils/axiosInstance";

const Clients: React.FC = () => {
  const classes = useStyles();
  // Dialog open states
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState<PolicyHolder | null>(
    null
  );

  // Sort state management
  const [orderBy, setOrderBy] = useState<string>("id");
  const [order, setOrder] = useState<"asc" | "desc">("asc");

  const { clients, hasMore, fetchClients, loading, error, updateParams } =
    useInfiniteScroll({
      initialParams: {
        limit: 20,
        order,
        orderBy,
        cursor: 0,
        filters: {},
      },
    });

  // Filter states
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [tinFilter, setTinFilter] = useState("");
  const [isCompany, setIsCompany] = useState<boolean | undefined>(undefined);
  const [hasAuthorization, setHasAuthorization] = useState<boolean | undefined>(
    undefined
  );
  const [foreign, setForeign] = useState<boolean | undefined>(undefined);

  // Refs for filter inputs
  const nameFilterRef = useRef<HTMLInputElement>(null);
  const idFilterRef = useRef<HTMLInputElement>(null);
  const tinFilterRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const handleSearch = useCallback(
    debounce((fieldName: string, value: string) => {
      const filters = value
        ? {
            [fieldName]: value,
          }
        : undefined;
      updateParams({ filters });
    }, 500),
    []
  );

  // Handle boolean filter toggles
  const handleToggle = (field: string, value: boolean) => {
    handleSearch(field, value ? "true" : "false");
  };

  const handleSort = (column: string) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
    updateParams({ order: isAsc ? "desc" : "asc", orderBy: column });
  };

  const renderSortIcon = (column: string) => {
    if (orderBy !== column) return null;
    return order === "asc" ? (
      <ArrowUpward
        color="action"
        className={classes.sortIcon}
        fontSize="small"
      />
    ) : (
      <ArrowDownward
        color="action"
        className={classes.sortIcon}
        fontSize="small"
      />
    );
  };

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenEditDialog = (client: PolicyHolder) => {
    const { hasAuthorization, ...rest } = client;
    setSelectedClient(rest);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setSelectedClient(null);
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (client: any) => {
    setSelectedClient(client);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setSelectedClient(null);
    setOpenDeleteDialog(false);
  };

  const handleAddClient = async (
    clientData: any,
    authorizationFile: File | null
  ) => {
    try {
      await addPolicyHolderApi({ ...clientData, roleId: 2 }, authorizationFile);
      handleCloseAddDialog();
      updateParams({ cursor: 0 });
      fetchClients(0);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClient = async (
    clientData: Partial<PolicyHolder>,
    authorizationFile: File | null
  ) => {
    try {
      await updatePolicyHolderApi(clientData, authorizationFile);
      handleCloseEditDialog();
      updateParams({ cursor: 0 });
      fetchClients(0);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteClient = async () => {
    try {
      await deletePolicyHolderApi(selectedClient!.id);
      handleCloseDeleteDialog();
      updateParams({ cursor: 0 });
      fetchClients(0);
    } catch (error) {
      console.error(error);
    }
  };

  // Reset filters using refs and state
  const handleResetFilters = () => {
    setNameFilter("");
    setIdFilter("");
    setTinFilter("");
    setIsCompany(undefined);
    setHasAuthorization(undefined);
    setForeign(undefined);
    setOrderBy("id");
    setOrder("asc");
    updateParams({ filters: {}, order: "asc", orderBy: "id" });

    // Optionally, focus the first input after reset
    nameFilterRef.current?.focus();
  };

  const downloadAuthorization = async (id: string) => {
    try {
      // Request the pre-signed URL from the backend
      const response = await axiosInstance.get(
        `/policy-holder/${id}/authorization`
      );

      const { url } = response.data;

      if (url) {
        // Create a temporary anchor element to trigger the download
        window.open(url, "_blank");
      } else {
        throw new Error("No download URL returned.");
      }
    } catch (error) {
      console.error("Download failed:", error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 2,
          flexWrap: "wrap",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          size="large"
          variant="contained"
          color="info"
          onClick={handleOpenAddDialog}
        >
          {t("clients.addClient")}
        </Button>
        <div className={classes.searchContainer}>
          <TextField
            label={t("clients.searchByName")}
            variant="outlined"
            fullWidth
            value={nameFilter}
            onChange={(e) => {
              setNameFilter(e.target.value);
              handleSearch("name", e.target.value);
            }}
            inputRef={nameFilterRef} // Assign ref here
          />
          <TextField
            label={t("clients.searchById")}
            variant="outlined"
            fullWidth
            value={idFilter}
            onChange={(e) => {
              setIdFilter(e.target.value);
              handleSearch("identificationNumber", e.target.value);
            }}
            inputRef={idFilterRef} // Assign ref here
          />
          <TextField
            label={t("clients.searchByTin")}
            variant="outlined"
            fullWidth
            value={tinFilter}
            onChange={(e) => {
              setTinFilter(e.target.value);
              handleSearch("tin", e.target.value);
            }}
            inputRef={tinFilterRef} // Assign ref here
          />
        </div>
        <Box display="flex" gap={2}>
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={isCompany === false}
                checked={isCompany === true}
                onChange={(e) => {
                  setIsCompany(e.target.checked);
                  handleToggle("isCompany", e.target.checked);
                }}
              />
            }
            label={t("clients.isCompany")}
          />
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={hasAuthorization === false}
                checked={hasAuthorization === true}
                onChange={(e) => {
                  setHasAuthorization(e.target.checked);
                  handleToggle("hasAuthorization", e.target.checked);
                }}
              />
            }
            label={t("clients.hasAuthorization")}
          />
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={foreign === false}
                checked={foreign === true}
                onChange={(e) => {
                  setForeign(e.target.checked);
                  handleToggle("foreign", e.target.checked);
                }}
              />
            }
            label={t("clients.foreign")}
          />

          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleResetFilters} // Use the reset handler
          >
            {t("clients.resetFilters")}
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <InfiniteScroll
        dataLength={clients.length}
        next={fetchClients}
        hasMore={hasMore}
        loader={
          <Box sx={{ textAlign: "center", py: 2 }}>
            <CircularProgress />
          </Box>
        }
        endMessage={
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ py: 2 }}
          >
            {t("clients.noMoreClients")}
          </Typography>
        }
      >
        {clients.length === 0 && !loading ? (
          <Alert severity="info">{t("clients.noClientsFound")}</Alert>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="clients table">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => handleSort("id")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("clients.ID")} {renderSortIcon("id")}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("firstName")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("clients.name")} {renderSortIcon("firstName")}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("identificationNumber")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("clients.identificationNumber")}{" "}
                    {renderSortIcon("identificationNumber")}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("tin")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("clients.tin")} {renderSortIcon("tin")}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("email")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("clients.email")} {renderSortIcon("email")}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("phone")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("clients.phone")} {renderSortIcon("phone")}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {t("clients.hasAuthorization")}
                  </TableCell>
                  <TableCell>{t("clients.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((client) => (
                  <TableRow key={client.id}>
                    <TableCell>{client.id}</TableCell>
                    <TableCell>
                      {client.firstName
                        ? `${client.firstName} ${client.lastName}`
                        : client.companyName}
                    </TableCell>
                    <TableCell>{client.identificationNumber}</TableCell>
                    <TableCell>{client.tin}</TableCell>
                    <TableCell>{client.email}</TableCell>
                    <TableCell>{client.phone}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {client.authorization ? (
                        <Button
                          onClick={() =>
                            downloadAuthorization(client.id.toString())
                          }
                        >
                          {t("clients.getAuthorization")}
                        </Button>
                      ) : (
                        <Clear />
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenEditDialog(client)}>
                        <Edit color="action" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleOpenDeleteDialog(client)}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </InfiniteScroll>

      <EditClientDialog
        clientData={null}
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onEditClient={handleAddClient}
      />

      <EditClientDialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        onEditClient={handleEditClient}
        clientData={selectedClient}
      />

      <DeleteClientDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDeleteClient={handleDeleteClient}
        clientData={selectedClient}
      />

      {loading && clients.length === 0 && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Clients;
