import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  logo: {
    cursor: "pointer",
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    flexGrow: 1,
  },
  navButton: {
    marginLeft: "40px",
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
});
