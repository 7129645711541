// src/api/policyHolderApi.ts

import axiosInstance from "../utils/axiosInstance";

export interface PolicyHolder {
  id: number;
  isCompany: boolean;
  firstName: string;
  lastName: string;
  identificationNumber: string;
  email: string;
  phone: string;
  companyName: string;
  tin: string;
  address: string;
  authorization: string;
  foreign: boolean;
  hasAuthorization?: boolean;
}

export interface GetPolicyHoldersResponse {
  data: PolicyHolder[];
  nextCursor: number;
}

export interface FetchPolicyHoldersParams {
  filters: Record<string, any>;
  cursor: number;
  limit: number;
  order: "asc" | "desc";
  orderBy: string;
}

export const fetchPolicyHoldersApi = async (
  params: FetchPolicyHoldersParams
): Promise<GetPolicyHoldersResponse> => {
  const {
    filters,
    cursor,
    limit,
    order = "asc",
    orderBy = "lastName",
  } = params;
  const response = await axiosInstance.get<GetPolicyHoldersResponse>(
    "/policy-holder",
    {
      params: {
        filters,
        cursor,
        limit,
        order,
        orderBy,
      },
    }
  );

  const responseData = response.data.data.map((policyHolder) => ({
    ...policyHolder,
    hasAuthorization: !!policyHolder.authorization,
  }));

  return { data: responseData, nextCursor: response.data.nextCursor };
};

export const addPolicyHolderApi = async (
  params: PolicyHolder,
  authorizationFile: File | null
) => {
  const { authorization, ...newPolicyHolder } = params;
  const response = await axiosInstance.post("/policy-holder", newPolicyHolder);
  if (authorizationFile && response.data?.length) {
    const name = response.data[0].company_name
      ? response.data[0].company_name
      : `${response.data[0].first_name} ${response.data[0].last_name}`;
    await uploadFile(authorizationFile, name, response.data[0].id);
  }
  return response.data;
};

export const updatePolicyHolderApi = async (
  params: Partial<PolicyHolder>,
  authorizationFile: File | null
) => {
  const { id, authorization, ...updatedPolicyHolder } = params;
  const response = await axiosInstance.put(
    `/policy-holder/${id}`,
    updatedPolicyHolder
  );

  // Upload file if it exists
  if (authorizationFile && response.data?.length) {
    // Combine first and last name if it's a personal client
    const clientName = response.data[0].company_name
      ? response.data[0].company_name
      : `${response.data[0].first_name} ${response.data[0].last_name}`;
    await uploadFile(authorizationFile, clientName, response.data[0].id);
  }

  // Remove file if it is purposefully sent as an empty string
  if (authorization === "") {
    await axiosInstance.delete(`/policy-holder/${id}/authorization`);
  }
  return response.data;
};

export const deletePolicyHolderApi = async (id: number) => {
  const response = await axiosInstance.delete(`/policy-holder/${id}`);
  return response.data;
};

export const getAuthorizationFile = async (id: string) => {
  try {
    const response = await axiosInstance.get(
      `/policy-holder/${id}/authorization`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting file:", error);
    throw error;
  }
};

export const uploadFile = async (
  file: File,
  clientName: string,
  id: string
) => {
  const formData = new FormData();
  formData.append("authorizationFile", file);
  formData.append("clientName", clientName);

  try {
    const response = await axiosInstance.put(
      `/policy-holder/${id}/authorization`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
