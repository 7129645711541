import React from "react";
import { Container, Typography, Box } from "@mui/material";
import LoginForm from "../../components/LoginForm/LoginForm";

const Login: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Premium Polisa Login
        </Typography>
        <LoginForm />
      </Box>
    </Container>
  );
};

export default Login;
