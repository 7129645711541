import _ from "lodash";

export const replaceNullsWithEmptyStrings = (obj: any) => {
  return _.cloneDeepWith(obj, (value) => {
    if (value === null) {
      return "";
    }
    // Return undefined to let lodash handle the rest
    return undefined;
  });
};
