import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface DeleteClientDialogProps {
  open: boolean;
  onClose: () => void;
  onDeleteClient: () => void;
  clientData: any;
}

const DeleteClientDialog: React.FC<DeleteClientDialogProps> = ({
  open,
  onClose,
  onDeleteClient,
  clientData,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("clients.confirmDelete")}</DialogTitle>
      <DialogContent>
        {t("clients.areYouSureDelete", {
          name: clientData?.firstName || clientData?.companyName,
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.cancel")}</Button>
        <Button onClick={onDeleteClient} color="error" variant="contained">
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteClientDialog;
