// src/context/AuthContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from "react";
import { login as loginService } from "../services/authService";

// Define the shape of the User object
interface User {
  id: string;
  name: string;
  email: string;
  // Add other user fields as necessary
}

// Define the shape of the AuthContext
interface AuthContextType {
  user: User | null;
  token: string | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
}

// Create the AuthContext with default values
export const AuthContext = createContext<AuthContextType>({
  user: null,
  token: null,
  login: async () => {},
  logout: async () => {},
  isAuthenticated: false,
});

// Define the props for AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// Create the AuthProvider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    // On initial load, check if token and user exist in localStorage
    const storedToken = localStorage.getItem("token");
    // const storedUser = localStorage.getItem("user");
    if (storedToken) {
      setToken(storedToken);
      // setUser(JSON.parse(storedUser));
    }
  }, []);

  // Function to handle login
  const login = async (username: string, password: string) => {
    const response = await loginService({ username, password });
    setToken(response.token);
    setUser(response.user);
    localStorage.setItem("token", response.token);
    // localStorage.setItem("user", JSON.stringify(response.user));
  };

  // Function to handle logout
  const logout = async () => {
    // await logoutService();
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  // Determine if the user is authenticated
  const isAuthenticated = !!localStorage.getItem("token") || !!token;

  return (
    <AuthContext.Provider
      value={{ user, token, login, logout, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};
