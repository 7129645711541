import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4">{t("dashboard.title")}</Typography>
    </Box>
  );
};

export default Dashboard;
