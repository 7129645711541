import {
  fetchPolicyHoldersApi,
  FetchPolicyHoldersParams,
  GetPolicyHoldersResponse,
} from "../api/policyHolderApi";

export const getPolicyHolders = async (
  params: FetchPolicyHoldersParams
): Promise<GetPolicyHoldersResponse> => {
  const response = await fetchPolicyHoldersApi(params);
  return response;
};
